import React from "react";
import VacancyList from "../src/components/Vacancy/List/VacancyList";
import {connect} from "react-redux";
import {resetSearchParameters, searchVacancies} from "../src/actions/vacancyActions";
import Home from "../src/components/Home/Home";
import PanelSearch from "../src/components/Search/Panel/PanelSearch";
import TopProfessions from "../src/components/TopProfessions/TopProfessions";
import Navbar from "../src/components/Shared/Navbar/Navbar";
import TopCategories from "../src/components/TopCategories/TopCategories";
import TopTags from "../src/components/TopTags/TopTags";
import {Header} from "semantic-ui-react";

const mapStateToProps = state => (
    {
        vacancies: state.vacancies,
        region: state.options.region,
        tags: state.options.tags,
        categories: state.options.categories
    }
);

const HomePage = ({vacancies, region, tags, categories}) => {
    return (
        <div>
            <Navbar id={"home-navbar"} />
            <Home />
            <TopProfessions region={region} />
            <TopCategories region={region} categories={categories} />
            <TopTags tags={tags} region={region} showTitle={true} />
            <br/>
            <PanelSearch id={"home-panel-search"}
                         activeTab={PanelSearch.Tabs.NewVacancies} />
            <VacancyList title={`Свежие вакансии в городе ${region.title}`}
                         vacancies={vacancies}
                         count={10}
                         usePagination={false} />
        </div>
    )
}

HomePage.getInitialProps = async ({store, isServer, pathname, query}) => {
    await store.dispatch(resetSearchParameters());
    await store.dispatch(searchVacancies(store));

    return {
        useNavbar:false
    };
}

export default connect(mapStateToProps)(HomePage);