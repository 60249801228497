import React from "react";
import SearchBase from "../Search/Base/SearchBase";
import Link from "next/link";
import {Header, Icon, Image} from "semantic-ui-react";
import Logo from "../../../public/images/logo.svg";
import "./Home.less";
import EmployerLink from "../Shared/Links/EmployerLink";
import RespondingLink from "../Shared/Links/RespondingLink";
import {connect} from "react-redux";

const MOSCOW_ID = 19;

const mapStateToProps = state => (
    {
        region: state.options.region,
    }
);

const Home = ({region}) => {

    const regionTitle = region.id === MOSCOW_ID
        ? "Работа в Москве"
        : `Работа в городе ${region.title}`;

    return (
        <div className="home landing">
            <div className="navigation">
                <div className="left">
                    <Link href="#" as={"#"}>
                        <a>
                            <Image className={"logo"} src={Logo} alt={"Аватаром найти работу"} />
                        </a>
                    </Link>
                    <RespondingLink className={"link"} text={"Разместить резюме"} />
                </div>
                <div className="right">
                    <EmployerLink text="Разместить вакансию" className="link" />
                    <Link href="#" as={"#"}>
                        <a className="link">
                            <Icon name={"user"} />€
                            Вход
                        </a>
                    </Link>
                </div>
            </div>

            <div className="title">
                <Header as={"h1"} className={"main"}>
                    {regionTitle}
                </Header>
                <Header as={"h2"}>
                    ВАКАНСИИ — РЯДОМ
                    <br/>
                    СМОТРИ — АВАТАРОМ
                </Header>
            </div>

            <div className="searching">
                <div className="searching-content">
                    <SearchBase inline={true} />
                    <p className="example">
                        Например: бухгалтер, водитель, курьер и т.д.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default connect(mapStateToProps)(Home);