import React from "react";
import {Header} from "semantic-ui-react";
import CategoryList from "../Category/CategoryList";
import "./TopCategories.less";

const TopCategories = ({categories, region}) => {
    return (
        <div className="top-categories">
            <Header as={"h2"} className="primary light top-categories-header">ПОПУЛЯРНЫЕ СФЕРЫ</Header>
            <CategoryList className={"top-categories-list"} categories={categories.slice(0, 15)} region={region} />
        </div>
    );
};

export default TopCategories;