import React from "react";
import {Header, Image} from "semantic-ui-react";
import Link from "next/link";
import "./TopProfessions.less";
import Driver from "../../../public/images/professions/driver.svg";
import Cook from "../../../public/images/professions/cook.svg";
import Courier from "../../../public/images/professions/courier.svg";
import Waiter from "../../../public/images/professions/waiter.svg";
import Security from "../../../public/images/professions/security.svg";
import Worker from "../../../public/images/professions/worker.svg";
import Manager from "../../../public/images/professions/manager.svg";
import Nanny from "../../../public/images/professions/nanny.svg";
import Medical from "../../../public/images/professions/medical.svg";
import Lawyer from "../../../public/images/professions/lawyer.svg";
import Tools from "../../tools/Tools";

const items = [
    {image: Driver, title: "Водитель", alt: "Вакансии водителя", url:"voditel" },
    {image: Cook, title: "Шеф-повар", alt: "Вакансии шеф-повара", url:"shef-povar"},
    {image: Courier, title: "Курьер", alt: "Вакансии курьера", url:"kurer"},
    {image: Waiter, title: "Официант", alt: "Вакансии официанта", url:"ofitsiant"},
    {image: Security, title: "Охрана", alt: "Вакансии охранника", url:"ohrannik"},
    {image: Worker, title: "Рабочий", alt: "Вакансии разнорабочего", url:"raznorabochii"},
    {image: Manager, title: "Менеджер", alt: "Вакансии менеджера", url:"menedzher"},
    {image: Nanny, title: "Няня", alt: "Вакансии няни, сиделки", url:"nyanya"},
    {image: Medical, title: "Медработник", alt: "Вакансии медработника", url:"meditsinskii-rabotnik"},
    {image: Lawyer, title: "Юрист", alt: "Вакансии юриста", url:"yurist"},
];

const getProfessions = (region) => {
    return items.map(item => {
        const link = `/rabota/${Tools.transliterate(region.title)}/${item.url}`;
        return (
            <div key={item.title} className="top-professions-list-item">
                <Link href={"/rabota/[...slug]"} as={link}>
                    <a>
                        <Image src={item.image} alt={item.alt}/>
                        <Header as="h3" className="primary light">{item.title}</Header>
                    </a>
                </Link>
            </div>
        );
    });
}

const TopProfessions = ({region}) => {

    const professions = getProfessions(region);

    return (
        <div className="top-professions">
            <Header as={"h2"} className="primary light">ПОПУЛЯРНЫЕ ПРОФЕССИИ</Header>
            <div className="top-professions-list">
                {professions}
            </div>
        </div>
    );
}

export default TopProfessions;